label {
  display: block;
  font-family: $ff-regular;
  font-size: 1.2rem;

  &.inline {
    display: flex;
    align-items: center;
  }

  &.light {
    font-family: $ff-light;
  }
}

input,
textarea,
select {
  font-family: $ff-regular;
  font-size: 1.2rem;
  padding: 0.8rem 0.6rem;
  border: 1px solid $color-grey-200;
  border-radius: 0.4rem;
  color: $color-grey-900;
  background-color: $color-white-200;
  width: 100%;

  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    background-color: $color-white-700;
    color: $color-grey-400;
  }

  &.success {
    border: 1px solid $color-success;
  }
  &.warning {
    border: 1px solid $color-warning;
  }
  &.danger {
    border: 1px solid $color-danger;
  }
  &.flat {
    border-radius: 0;
  }
}

input::placeholder {
  font-family: $ff-regular;
  font-size: 1.2rem;
  color: $color-grey-300;
}

input[type="file"] {
  border: 0;
}

select[multiple] option {
  font-size: 1.3rem;
  padding: 0.7rem;
}

select option {
  font-family: "Roboto-Regular";
  font-size: 2rem;
  background-color: $color-white-400;
}

// Input sizes
$inputSizes: (xs, "0.1"), (sm, "0.2"), (md, "0.4"), (lg, "0.6"), (xl, "0.8"),
  (xxl, "1");
@each $name, $size in $inputSizes {
  select.input-#{$name},
  input.input-#{$name} {
    padding: #{$size}rem 0.6rem;
  }
}

input:read-only {
  background-color: $color-white-400;
}

input[type="checkbox"],
input[type="radio"] {
  width: 1.3rem;
  height: 1.3rem;
}

textarea {
  height: 7rem;
}
