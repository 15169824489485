@import "abstract/variables";
@import "abstract/mixins";

.app__user--account--wrapper {
  @include flex-center;

  .app__user--account-card {
    width: 100%;
  }

  .app__user--account--container {
    display: grid;
    grid-template-columns: 20rem auto;
    height: auto;

    .app__user--account-right-col {
      margin-left: 2rem;

      .app__user--account-subtitle {
        color: $color-grey-600;
        font-size: 1.3rem;
        margin-top: 2.5rem;
      }
    }
  }
}

