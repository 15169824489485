@import "abstract/variables";

.app__no-data-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    filter: grayscale(100%);
  }

  &-errors li {
    padding: 1.4rem 0;
    font-size: 1.4rem;
  }
}
