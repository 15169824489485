@import "abstract/variables";
@import "abstract/mixins";

.app__2fa--wrapper {
  height: 90%;

  .app__2fa--content-wrapper {
    @include flex-center;
    position: relative;
    height: 100%;
    overflow: hidden;

    .app__2fa--content {
      opacity: 0;
      position: absolute;
      transition: transform 0.4s linear;
      transform: translateX(50rem);

      &-active {
        transform: translateX(0);
        opacity: 1;
      }

      &-inactive {
        transform: translateX(-50rem);
        opacity: 0;
      }

      img.hero {
        width: 25rem;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      img.app__2fa--barcode {
        width: 15rem;
        height: auto;
      }

      &-text {
        font-size: 1.3rem;
        line-height: 2rem;
        text-align: center;
      }

      a {
        color: $color-info;
      }

      &-code-wrapper {
        @include flex-center;

        input.app__2fa--verify-code {
          border: 0;
          border-radius: 0;
          border-bottom: 0.1rem solid grey;
          width: 2.5rem;
          text-align: center;
        }
      }
    }
  }
}