@import "abstract/mixins";
@import "abstract/variables";

.radio__wrapper {
  --dimension: 3rem;
  .radio__circle {
    border: 2px solid $color-grey-200;
    border-radius: 50%;
    width: var(--dimension);
    height: var(--dimension);
    overflow: hidden;
    cursor: pointer;

    &-checked {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-info;
      color: $color-white-100;
      font-size: calc(var(--dimension) / 3);
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
