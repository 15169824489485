// Flex
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-col {
  flex-direction: column;
}

@mixin backdrop {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(#000000, 0.8);
  backdrop-filter: blur(1rem);
}
