@import "abstract/variables";
@import "abstract/mixins";

.app__2fa--confirm-badge {
  font-size: 4rem;
  text-align: center;
  display: block;
  color: $color-green-sea
}

ul.app__2fa--backup-codes {
  columns: 10rem 2;
  font-size: 1.2rem;
  text-align: center;

  li {
    margin-bottom: 1rem;
  }
}
