@import "abstract/variables";
@import "abstract/mixins";

.app__item--plan-card {
  text-align: center;
  color: $color-grey-900;
  border: 0.1rem solid $color-grey-400;
  box-shadow: 0 0 0.5rem 0 $color-grey-200;
  border-radius: 0.2rem;
  width: 100%;
  margin: 1rem;
  padding: 1rem;

  &:hover {
    border: 0.1rem solid $color-brand-primary;
    box-shadow: 0 0 0.5rem 0 $color-brand-primary;
  }

  .app__item--plan-property {
    font-size: 1.2rem;
    margin: 0.5rem;
    color: $color-grey-1000;

    .app__item--plan-property-value {
      display: inline-block;
      color: $color-info;
      font-weight: 600;
    }
  }

  .app__item--plan-name {
    font-weight: 700;
    color: $color-brand-primary;
    font-size: 1.7rem;
  }

  .app__item--plan-type {
    font-weight: 600;
    color: $color-black;
    font-size: 1.3rem;
  }
  .plan__properties {
    font-size: 1.1rem;
    text-align: left;
    margin: 0.5rem;
  }
  .plan__property-true {
    display: inline-block;
    color: $color-success;
  }
  .plan__property-false {
    display: inline-block;
    color: $color-danger;
  }
  .app__item--plan-button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    color: $color-brand-primary;
    background-color: transparent;
    border: 0.1rem solid $color-brand-primary;

    &:hover {
      color: $color-white-100;
      background-color: $color-brand-primary;
    }
  }
  .app__item--plan-info {
    margin-top: 0.6rem;
    color: $color-info;
    font-weight: 500;
  }
}
