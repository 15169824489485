@import "layouts/listing/listing";

.app__user--card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 4rem;
}

.app__user--add-tags {
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
}