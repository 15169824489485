@import "abstract/variables";

.app__user--profile-board {
  width: 100%;
  margin-bottom: 7rem;

  &-value {
    font-size: 1.3rem;
  }

  & .underlined {
    position: relative;
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid var(--color-grey-200);
  }
}
