@import "abstract/variables";

.app__data--search-wrapper {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-family: $ff-heading-regular;

  .app__data--search-icon-primary {
    font-size: 1.5rem;
    color: $color-grey-700;
    line-height: 0;
  }

  .app__data--search-input-wrapper {
    position: relative;
    width: 0;
    opacity: 0;
    transition: width 0.4s ease-out;

    &.app__data--search-input-wrapper-show {
      width: 23rem;
      opacity: 1;
    }

    .app__data--search-icon {
      position: absolute;
      font-size: 1.4rem;
      color: $color-grey-400;
      top: 0.9rem;
      left: 0.8rem;
    }

    input[type="search"].app__data--search-input {
      padding-left: 2.5rem;
      width: 100%;
      font-size: 1.5rem;

      &::-webkit-input-placeholder {
        font-size: 1.3rem;
      }
    }
  }
}
