@import "abstract/variables";

.app__item-add-tag-bar {
  margin: auto;
  height: 40px;
  width: 95%;
  display: flex;
  border-radius: 0.6rem;
  border: 0.19rem solid $color-grey-400;

  .app__item-input-bar {
    border: none;
  }
  .app__item-icon {
    margin: 0.8rem 0.5rem 0 1rem;
    font-size: 1.8rem;
    color: $color-grey-400;
    cursor: pointer;
  }
}

.app__item-tag-items {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  .app__item-tag-item-container {
    width: fit-content;
    margin: 1rem;
    display: flex;
    background-color: $color-brand-secondary;
    padding: 0.5rem;
    border-radius: 10%;

    .app__item-tag-item-icon {
      display: none;
      margin: 0.35rem 0.1rem 0 0.3rem;
      cursor: pointer;
      font-size: 1.2rem;
      color: $color-danger;
    }

    &:hover {
      background-color: $color-grey-100;

      .app__item-tag-item-icon {
        display: block;
      }
      .app__item-tag-item {
        color: $color-black;
      }
    }
    .app__item-tag-item {
      font-family: $ff-heading-regular;
      font-size: 1.3rem;
      width: auto;
      color: $color-white-100;
    }
    .app__item-icon {
      padding: 0.2rem;
      font-size: 1.8rem;
      color: $color-danger;
      cursor: pointer;
    }
  }
}

.app__item-manage-tags-button {
  display: flex;
  justify-content: center;
  margin: 2rem 0 2rem 0;
  width: 100%;
}
