@import "abstract/variables";

.app__search--menu {
  border: 1px solid $color-grey-200;
  background-color: $color-white-100;
  position: absolute;
  top: 4.1rem;
  width: 40rem;
  height: 0rem;
  max-height: 20rem;
  box-shadow: 0 0 3rem $color-white-900;
  transition: height 0.06s linear;
  visibility: hidden;
  z-index: 10;

  &.app__search--menu-show {
    height: auto;
    visibility: visible;
  }

  .app__search--menu-title {
    font-size: 1.1rem;
    font-family: $ff-heading-regular;
  }

  ul.app__search--menu-items li {
    font-size: 1.2rem;
    a {
      display: block;
      color: $color-dark;
      width: 100%;
      padding: 10px;

      &:hover {
        background-color: $color-white-600;
      }
    }
  }
}
