@import "abstract/variables";


  .properties {
    height: auto;
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
  }
  .input-flex {
    margin: 0.1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    .input-items {
      margin: 0.3rem;
      min-width: 20rem;
    }
    .flex-item {
      margin-right: 1rem;
    }
  }

