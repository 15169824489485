@import "abstract/variables";
@import "layouts/listing/listing";

.text__icon {
  --dimension: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--dimension);
  height: var(--dimension);
  border-radius: 50%;
  font-family: "Roboto-Bold";
  font-size: 1.3rem;
}
.page-header {
  font-family: "poppins-medium";
  font-size: 2rem;
}
.app__data--listing--filter-items {
  display: flex;
  flex-direction: row;
}
.item-header {
  margin-left: 10rem;
}
.customer__item-icon {
  margin-right: 0.3rem;
  color: $color-danger;
}
.tag {
  display: flex;
}
.filter-input-flex{
  display:flex;
  flex-flow: row;
  justify-content: flex-start;
}
