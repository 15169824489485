@import "abstract/mixins";
@import "abstract/variables";

.app__navigation--item > a {
  --nav-item-width: 6rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  color: $color-grey-700;
  transition: all 0.4s linear;
  margin: 2.5rem 0;
  width: var(--nav-item-width);

  &.active,
  &:hover {
    --border-left-size: 0.3rem;
    color: $color-white-100;
    border-left: var(--border-left-size) solid $color-brand-secondary;
    width: calc(var(--nav-item-width) - var(--border-left-size));
  }

  i.app__navigation--item-icon {
    @include flex-center;
    position: relative;
    display: block;
    font-size: 2rem;
    cursor: pointer;
  }
}
