.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 0.1rem solid $color-grey-100;
}

.table > thead > tr > th {
  border-bottom: 0.19rem solid $color-grey-100;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 0.8rem;
  line-height: 1.42857143;
  text-align: left;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  background-color: $color-white-100;
  width:100%;
  max-width: 100%;
  border-top: 0.3rem solid $color-grey-200;
  padding: 1rem;
  border-radius: 0.3rem;

  thead th {
    font-size: 1.3rem;
    font-family: $ff-regular;
    text-align: left;
    padding-bottom: 5px;
  }

  tbody {
    tr:hover {
      background-color: $color-white-400;

      .table__action--button,
      .table__action--button-divider {
        visibility: visible;
      }
    }

    td {
      font-size: 1.2rem;

      .table__col--main {
        display: flex;
        align-items: center;

        .table__col--main-icon {
          --dimension: 3rem;
          @include flex-center;
          width: var(--dimension);
          height: var(--dimension);
          border-radius: 50%;
          font-family: $ff-bold;
          font-size: 1.3rem;
        }
      }

      a.table__title {
        font-size: 1.4rem;
        color: $color-dark;

        &:hover {
          color: $color-info;
        }
      }

      .table__description {
        width: 100%;
        max-width: 50rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: lighten($color-dark, 20%);
      }

      .table__action--button-wrapper {
        text-align: right;

        ul {
          li {
            display: inline;
            visibility: hidden;

            a {
              font-size: 1.1rem;
              color: $color-info;

              &:hover {
                color: $color-brand-primary;
              }
            }

            &.table__action--button-divider {
              color: $color-grey-600;
              margin: 0 0.5rem;
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
}
