@import "abstract/variables";

.app__navigation--item-department-menu {
  border-right: 0.1rem solid $color-brand-dark-200;
  border-bottom: 0.1rem solid $color-brand-dark-200;
  border-bottom-right-radius: 0.4rem;
  position: absolute;
  left: 6rem;
  top: 11.8rem;
  width: 20rem;
  height: 30rem;
  z-index: 2;
  background-color: $color-brand-dark-100;
  opacity: 0;
  transform: translateX(-400px);
  transition: opacity 0.2s ease-in-out;

  &.show {
    opacity: 1;
    transform: translateX(0);
  }

  .app__navigation--item-department-menu-header {
    color: $color-white-100;
    position: relative;

    i.app__navigation--item-department-menu-search-icon {
      line-height: 0;
      font-size: 1.2rem;
      position: absolute;
      top: 1.5rem;
      left: 0.6rem;
      color: $color-grey-400;
    }

    input[type="text"].app__navigation--item-department-menu-search {
      border: 0.1rem solid $color-brand-light-200;
      background-color: $color-brand-light-200;
      color: $color-white-100;
      border-radius: 0;
      width: 100%;
      padding-left: 2.5rem;
      outline: none;
      border: none;

      &:focus,
      &:active {
        outline: none;
        border: none;
      }

      &::placeholder {
        color: $color-grey-400;
        font-size: 1.1rem;
      }
    }
  }

  .app__navigation--item-department-menu-body {
    height: 90%;
    overflow: auto;

    ul > li a {
      font-size: 1.2rem;
      display: inline-block;
      color: $color-white-100;
      width: 100%;
      height: 100%;
      padding: 1rem 0.9rem;
      transition: background-color 0.3s linear;

      &:hover {
        background-color: $color-grey-900;
      }
    }
  }
}
