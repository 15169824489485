@import "abstract/variables";

.app__drawer-modal--container {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  height: 100%;

  .app__drawer-modal--card {
    border-radius: 0.4rem;
    width: 95%;
    padding: 1rem;

    a.app__drawer-modal--card-read-more {
      color: $color-info;

      &:hover {
        color: $color-brand-primary;
      }
    }
  }
}
