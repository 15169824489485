a.btn,
button.btn {
  padding: 0.8rem;
  font-family: inherit;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  width: fit-content;
  transition: background-color 0.1s linear, color 0.1s linear;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:active,
  &:focus {
    outline: none;
  }

  // ********** Normal Buttons **********

  // Default Button
  &.btn--default {
    background-color: $color-grey-100;
    color: $color-grey-900;
    transition: background-color 0.3s linear;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-grey-100, 15%);
    }
  }

  // Default Button
  &.btn--primary {
    background-color: $color-brand-primary;
    color: $color-white-100;
    transition: background-color 0.3s linear;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-brand-primary, 15%);
    }
  }

  // Success Button
  &.btn--success {
    background-color: $color-success;
    color: $color-white-100;
    transition: background-color 0.3s linear;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-success, 4%);
    }
  }

  // Info Button
  &.btn--info {
    background-color: $color-info;
    color: $color-white-100;
    transition: background-color 0.3s linear;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-info, 8%);
    }
  }

  // Warning Button
  &.btn--warning {
    background-color: $color-warning;
    color: $color-grey-900;
    transition: background-color 0.3s linear;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-warning, 8%);
    }
  }

  // Danger Button
  &.btn--danger {
    background-color: $color-danger;
    color: $color-white-100;
    transition: background-color 0.3s linear;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-danger, 15%);
    }
  }

  // Dark Button
  &.btn--dark {
    background-color: $color-grey-900;
    color: $color-white-100;

    &:hover,
    &:active,
    &:focus {
      background-color: lighten($color-grey-900, 15%);
    }
  }

  // Pumpkin Button
  &.btn--pumpkin {
    background-color: $color-pumpkin;
    color: $color-white-100;

    &:hover,
    &:active,
    &:focus {
      background-color: lighten($color-pumpkin, 15%);
    }
  }

  // Icon Button
  &.btn--icon i {
    margin-right: 0.7rem;
    font-size: 1.3rem;
    line-height: 0;
    display: inline-block;
  }

  // ********** Border or Outline Buttons **********
  &.btn--outline-default {
    background-color: transparent;
    border: 0.1rem solid $color-grey-200;
    color: $color-grey-900;
    transition: all 0.2s linear;

    &:hover,
    &:active,
    &:focus {
      border: 0.1rem solid darken($color-grey-100, 20%);
      box-shadow: 0 0 0.2rem $color-grey-900;
    }
  }

  &.btn--outline-primary {
    background-color: transparent;
    border: 0.1rem solid $color-brand-primary;
    color: $color-brand-primary;
    transition: all 0.2s linear;

    &:hover,
    &:active,
    &:focus {
      background-color: lighten($color-brand-primary, 35%);
      box-shadow: 0 0 0.2rem $color-brand-primary;
    }
  }

  // Success Button
  &.btn--outline-success {
    background-color: transparent;
    border: 0.1rem solid $color-success;
    color: $color-success;
    transition: background-color 0.3s linear;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-success, 15%);
      color: $color-white-100;
      border: 0.1rem solid transparent;
    }
  }

  // Warning Button
  &.btn--outline-warning {
    background-color: transparent;
    border: 0.1rem solid $color-warning;
    color: $color-warning;
    transition: background-color 0.3s linear;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-warning, 8%);
      color: $color-white-100;
      border: 0.1rem solid transparent;
    }
  }

  // Info Button
  &.btn--outline-info {
    background-color: transparent;
    border: 0.1rem solid $color-info;
    color: $color-info;
    transition: all 0.2s linear;

    &:hover,
    &:active,
    &:focus {
      background-color: lighten($color-info, 55%);
      box-shadow: 0 0 0.2rem $color-info;
    }
  }

  // Danger Button
  &.btn--outline-danger {
    background-color: transparent;
    border: 0.1rem solid $color-danger;
    color: $color-danger;
    transition: background-color 0.3s linear;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($color-danger, 15%);
      color: $color-white-100;
      border: 0.1rem solid transparent;
    }
  }

  // Dark Button
  &.btn--outline-dark {
    background-color: transparent;
    border: 0.1rem solid $color-grey-900;
    color: $color-grey-900;

    &:hover,
    &:active,
    &:focus {
      background-color: lighten($color-grey-900, 15%);
      color: $color-white-100;
      border: 0.1rem solid transparent;
    }
  }

  // Icon Button
  &.btn--outline-icon i {
    margin-right: 0.7rem;
  }

  // Disabled Button
  &:disabled {
    cursor: not-allowed;
    background-color: $color-grey-200;
    color: $color-grey-900;

    &:hover {
      background-color: $color-grey-200;
    }
  }
  // Block Button
  &.btn--block {
    display: block;
    width: 100%;
  }

  // Block Rounded Buttons
  &.btn--block-rounded {
    @extend .btn--block;
    border-radius: 2rem;
  }

  // Button Sizes
  $buttonSizes: (xs, "0.2"), (sm, "0.5"), (md, "0.6"), (lg, "0.8"), (xl, "0.9"),
    (xxl, "1.2");

  @each $name, $size in $buttonSizes {
    &.btn--#{$name} {
      padding: #{$size}rem;
    }
  }
}

a.btn {
  text-align: center;
}

// Dropdown Buttons
.btn--dropdown-wrapper {
  position: relative;

  .btn--dropdown-container {
    height: 100%;
    display: flex;

    button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .btn--dropdown {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 3rem;
      height: 100%;
      width: 2.5rem;
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }

    .btn--dropdown-info {
      background: darken($color-info, 6%);
      color: $color-white-100;
    }
    .btn--dropdown-danger {
      background: darken($color-danger, 6%);
      color: $color-white-100;
    }
  }
}
