@import "abstract/variables";

.app__user--profile-form {
  width: 90%;
  max-width: 90%;
  margin-bottom: 7rem;

  &-dial-code-mobile {
    display: flex;
    display: grid;
    grid-template-columns: 16rem 1fr;
  }

  &-tags {
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
  }
}
