@import "abstract/mixins";
@import "abstract/variables";

.app__modal--basic-wrapper {
  @include backdrop;
  @include flex-center;

  .app__modal--basic-container {
    @include flex-center;
    @include flex-col;
    z-index: 1000;
    background-color: $color-white-200;
    padding: 2rem;
    border-radius: 0.4rem;
    position: relative;
    

    .app__modal--basic-close-container {
      padding: 1rem;

      i.app__modal--basic-close {
        position: absolute;
        font-size: 1.5rem;
        top: 1rem;
        right: 1rem;
        color: $color-grey-600;
        padding: 0.9rem;
        line-height: 0.7rem;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.2s linear;

        &:hover {
          background-color: $color-grey-200;
          color: $color-dark;
        }
      }
    }
  }
}
