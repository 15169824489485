$color-brand-primary: #f45a40;
$color-brand-secondary: #ff6750;
$color-brand-light-100: #7e7f84;
$color-brand-light-200: #67686d;
$color-brand-dark-100: #2a2d32;
$color-brand-dark-200: #1f2025;

$color-success: #2eb85c;
$color-muted: #6c757d;
$color-info: #17a2b8;
$color-warning: #f9b115;
$color-danger: #dc3545;
$color-dark: #343a40;
$color-black: #000000;
$color-green-sea: #16a085;
$color-orange: #f39c12;
$color-carrot: #e67e22;
$color-pumpkin: #d35400;
$color-indigo: #4b0082;
$color-river: #3498db;
$color-belize: #2980b9;
$color-wisteria: #8e44ad;
$color-mazrine: #273c75;
$color-berry: #b53471;
$color-taupe: #795C5F;

$color-white-100: #ffffff;
$color-white-200: #fdfdfd;
$color-white-300: #fbfbfb;
$color-white-400: #f9f9f9;
$color-white-500: #f7f7f7;
$color-white-600: #f5f5f5;
$color-white-700: #f3f3f3;
$color-white-800: #f2f2f2;
$color-white-900: #ececec;

$color-grey-100: #ebedef;
$color-grey-200: #d8dbe0;
$color-grey-300: #c4c9d0;
$color-grey-400: #b1b7c1;
$color-grey-500: #9da5b1;
$color-grey-600: #8a93a2;
$color-grey-700: #768192;
$color-grey-800: #636f83;
$color-grey-900: #4f5d73;
$color-grey-1000: #3d4147;

$background-primary: #f4f5f9;
$background-secondary: #fafafb;
$background-tertiary: #ecedf0;

$ff-heading-bold: "Poppins-Bold";
$ff-heading-medium: "Poppins-Medium";
$ff-heading-regular: "Poppins-Regular";
$ff-heading-light: "Poppins-Light";
$ff-heading-thin: "Poppins-Thin";
$ff-bold: "Roboto-Bold";
$ff-regular: "Roboto-Regular";
$ff-light: "Roboto-Light";
$ff-thin: "Roboto-Thin";
