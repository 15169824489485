@import "abstract/variables";
@import "abstract/mixins";

.tag-container {
    display :flex;
    flex-flow: row;
    flex-wrap: wrap;

    .tag-item {
        margin: 0.5rem;
        padding : 0.4rem;
        background-color: $color-grey-600;
        color: $color-white-100;
        font-size: 1.2rem;
        font-weight: bold;
        border-radius: 0.6rem;
    }
}