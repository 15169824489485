@import "abstract/variables";


i.app__header--search-icon {
  position: absolute;
  display: inline-block;
  font-size: 1.8rem;
  color: $color-grey-600;
  top: 1.5rem;
  left: 1.5rem;
  cursor: pointer;
  z-index: 1;
}

.app__header--search-form {
  padding-left: 2.9rem;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  width: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &:focus {
    outline: none;
  }
  &:-moz-focus-inner {
    border: 0;
  }

  &.app__header--search-form-show {
    opacity: 1;
    width: 100%;
  }
}
