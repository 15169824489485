// Poppins Font Family
@font-face {
  font-family: Poppins-Black;
  src: url("../../fonts/Poppins/Poppins-Black.ttf");
}
@font-face {
  font-family: Poppins-Bold;
  src: url("../../fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: Poppins-Medium;
  src: url("../../fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins-Regular;
  src: url("../../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins-Light;
  src: url("../../fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: Poppins-Thin;
  src: url("../../fonts/Poppins/Poppins-Thin.ttf");
}

// Roboto Font Family
@font-face {
  font-family: Roboto-Black;
  src: url("../../fonts/Roboto/Roboto-Black.ttf");
}
@font-face {
  font-family: Roboto-Bold;
  src: url("../../fonts/Roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: Roboto-Regular;
  src: url("../../fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: Roboto-Light;
  src: url("../../fonts/Roboto/Roboto-Light.ttf");
}
@font-face {
  font-family: Roboto-Thin;
  src: url("../../fonts/Roboto/Roboto-Thin.ttf");
}
