@import "abstract/variables";

.dropdown__search--wrapper {
  border-radius: 0.4rem;
  position: relative;

  .dropdown__search--select {
    padding: 1rem 0.6rem;
    font-size: 1.2rem;
    font-family: $ff-regular;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .fas.fa-times:hover {
      color: $color-grey-900;
    }
  }

  .dropdown__search--options {
    margin-top: 0.1rem;
    position: absolute;
    background: white;
    width: 100%;
    border: 0.1rem solid $color-grey-200;
    z-index: 1;

    .dropdown__search--search-box {
      position: relative;
      padding: 1rem 0.6rem;

      i.dropdown__search--icon {
        position: absolute;
        top: 2rem;
        left: 1.5rem;
        font-size: 1.2rem;
      }

      input.dropdown__search--input {
        padding-left: 2.5rem;
      }
    }

    ul.dropdown__search--option {
      max-height: 15rem;
      overflow: auto;

      li {
        padding: 1rem 0.6rem;
        cursor: pointer;
        font-size: 1.2rem;
        transition: all 0.3s ease-in-out;

        &:hover {
          font-family: "Roboto-Regular";
          background: $color-white-800;
        }
      }
    }
  }
}
