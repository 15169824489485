@import "abstract/variables";
@import "abstract/mixins";

.customer-info {
  width: auto;

  .customer {
    width: 60%;
    margin: auto;
  }

  .customer__product-type {
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .input-items {
      margin: 0.3rem;
      min-width: 45%;
    }
  }

  #customer-sub-domain {
    margin-left: 0.5rem;
    padding: 0;
  }

  .fa-angle-double-right {
    margin-left: 0.5rem;
    font-size: 1.5rem;
  }

  .app__2fa--content {
    opacity: 0;
    position: absolute;
    transition: transform 0.4s linear;
    transform: translateX(50rem);

    &-active {
      transform: translateX(0);
      opacity: 1;
    }

    &-inactive {
      transform: translateX(-50rem);
      opacity: 0;
    }

    img.hero {
      width: 25rem;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    img.app__2fa--barcode {
      width: 15rem;
      height: auto;
    }

    &-text {
      font-size: 1.3rem;
      line-height: 2rem;
      text-align: center;
    }

    a {
      color: $color-info;
    }

    &-code-wrapper {
      @include flex-center;

      input.app__2fa--verify-code {
        border: 0;
        border-radius: 0;
        border-bottom: 0.1rem solid grey;
        width: 2.5rem;
        text-align: center;
      }
    }
  }
  .add__customer-dropdown-container {
    border: 0.1rem solid $color-grey-400;
    z-index: 10;
    position: relative;
    max-height: 10rem;
    overflow-y: scroll;
    .add__customer-dropdown-list-item {
      list-style: none;
      padding: 0.5rem;
      font-size: 1.2rem;

      &:hover {
        background-color: $color-info;
        color: $color-white-100;
      }
    }
  }
}
