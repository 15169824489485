@import "abstract/variables";

.app__profile--menu {
  position: absolute;
  top: 4.7rem;
  left: -15rem;
  background-color: $color-white-300;
  padding: 1rem 0;
  z-index: 2;
  box-shadow: 0 0 2px $color-grey-400;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  width: 21rem;

  .app__profile--header-container {
    display: flex;
    padding: 1rem;

    .app__profile--header-name {
      font-size: 1.7rem;
    }
    .app__profile--header-email {
      font-size: 1rem;
    }
  }

  ul.app__profile-menu-list li {
    &.divider {
      border-top: 1px solid $color-grey-200;
    }

    a {
      display: block;
      padding: 1rem 0;
      padding-left: 1rem;
      font-size: 1.3rem;
      color: $color-grey-900;
      transition: all 0.2s ease-out;

      &:hover {
        background-color: $color-info;
        color: $color-white-100;
      }
    }
  }
}
