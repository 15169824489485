@import "abstract/variables";

.customer__input-items {
  display: flex;
  flex-flow: row;
  border: solid 0.1rem $color-grey-200;
  border-radius: 0.4rem;
  .customer__input {
    border: none;
  }
}
.customer__input-icon {
  font-size: 1.5rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
  color: $color-info;
  cursor: pointer;
}
.customer__input-item-container {
  border: solid 0.1rem $color-grey-200;
  margin-top: 0.5rem;
  padding: 0.5rem;
  font-size: 1.1rem;
  .customer__input-item {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 0.3rem;
  }
  .customer__input-item-icon {
    margin-left: 0.4rem;
    color: $color-info;
    cursor: pointer;
  }
}
