@import "abstract/variables";

.app__user--card {
  width: 20rem;
  height: 27rem;
  border: 0.1rem solid var(--color-grey-200);
  background-color: var(--color-white-100);
  border-radius: 0.6rem;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 27px 9px 24px -21px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 27px 9px 24px -21px rgba(0, 0, 0, 0.33);
  box-shadow: 27px 9px 24px -21px rgba(0, 0, 0, 0.33);

  // Hover Start
  &:hover .app__user--card-header .app__user--card-avatar {
    width: 10rem;
    height: 10rem;
    font-size: 3.5rem;
  }

  &:hover .app__user--card-header .app__user--card-title {
    font-size: 1.5rem;
  }

  &:hover .app__user--card-header .app__user--card-subtitle {
    font-size: 1.1rem;
  }

  &:hover .app__user--card-footer {
    transition: transform 0.5s ease-out;
    transform: translateY(0);
  }
  &:hover .app__user--card-actions {
    transition: opacity 0.8s linear;
    opacity: 1;
  }
  // Hover End

  .app__user--card-actions {
    position: absolute;
    font-size: 1rem;
    right: 0;
    line-height: 0;
    top: 0.5rem;
    opacity: 0;
    transition: opacity 0.4s linear;

    .app__user--card-edit {
      margin: 1rem 0;
      margin-right: 1rem;

      &:hover {
        color: var(--color-brand-primary);
      }
    }

    .app__user--card-info {
      right: 2rem;
      margin: 1rem 0;
      margin-right: 1rem;

      &:hover {
        color: var(--color-info);
      }
    }
  }

  .app__user--card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .app__user--card-avatar {
      padding: 0.5rem;
      border: 0.1rem solid var(--color-grey-200);
      transition: all 0.3s linear;
    }

    .app__user--card-title {
      font-family: "Roboto-Regular";
      font-size: 1.9rem;
      transition: font-size 0.3s linear;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 18rem;
      text-align: center;
    }

    .app__user--card-subtitle {
      font-size: 1.5rem;
      transition: font-size 0.3s linear;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 18rem;
      text-align: center;
      min-height: 1rem;
    }
  }

  .app__user--card-footer {
    padding: 0 1rem;
    transition: transform 0.5s linear;
    transform: translateY(4rem);

    ul.app__user--card-details > li {
      .app__user--card-label {
        font-size: 1.2rem;
        color: var(--color-grey-300);
        min-width: 1.3rem;
        display: inline-block;
      }

      .app__user--card-value {
        font-size: 1.2rem;
        color: var(--color-grey-900);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 15rem;
      }
    }
  }
}
