// @import "abstract/variables";
@import "../../../assets/scss/abstract/variables";

.avatar {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar__border {
  padding: 0.5rem;
  border: 0.1rem solid $color-grey-200;
}

.avatar__flat {
  border-radius: 0;
}
.avatar__flat-10 {
  border-radius: 1rem;
}

div.avatar__transparent {
  background-color: rgba(#000000, 0.6);
  color: $color-white-100;
  border: 0.1rem dotted $color-white-100;
}

// Sizes
.avatar--xs {
  @extend .avatar;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
}
.avatar--sm {
  @extend .avatar;
  width: 3rem;
  height: 3rem;
  font-size: 1rem;
}
.avatar--md {
  @extend .avatar;
  width: 4rem;
  height: 4rem;
  font-size: 1.7rem;
}
.avatar--lg {
  @extend .avatar;
  width: 8rem;
  height: 8rem;
  font-size: 3rem;
}
.avatar--xl {
  @extend .avatar;
  width: 10rem;
  height: 10rem;
  font-size: 4rem;
}

.avatar--xxl {
  @extend .avatar;
  width: 15rem;
  height: 15rem;
  font-size: 7rem;
}
