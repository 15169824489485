.container {
  width: 90%;
  margin: auto;
}

.container__fluid {
  width: 100%;
  margin: 15px auto;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

// Text
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

// Justify Content
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}

// Align Items
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}

.block {
  display: block;
}

.hidden,
.hide {
  display: none;
}

.default-hide {
  opacity: 0;
  height: 0;
}

.cp {
  cursor: pointer;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

// Rules
.hr {
  border-top: 1px solid $color-grey-200;
}
.vr {
  border-right: 1px solid $color-grey-200;
}

.debug {
  border: 1px solid red;
}

// Width and Height
@for $i from 0 through 100 {
  .w-#{$i} {
    width: round(percentage($i/100));
  }
  .h-#{$i} {
    height: round(percentage($i/100));
  }
}
