@import "abstract/variables";

.app__user--profile-form-heading {
  margin-top: 1rem;
}

.app__user--profile-form {
  width: 90%;
  max-width: 90%;

  img {
    justify-self: end;
  }

  .app__user--label-entry {
    font-size: 1.4rem;
  }

  .app__user--profile-form-upload {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
