@import "abstract/variables";

.app__plan-form {
  width: 92%;
  margin: auto;
  margin-bottom: 7rem;

  .plan-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 10rem;
  }

  &-dial-code-mobile {
    display: flex;
    display: grid;
    grid-template-columns: 16rem 1fr;
  }

  &-tags {
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
  }
}