@import "abstract/variables";
@import "abstract/mixins";

.app__user--account-sidebar-top {
  @include flex-center;
  flex-direction: column;
  padding: 2rem;

  .app__user--account-sidebar-name {
    font-size: 1.3rem;
  }
  .app__user--account-sidebar-title {
    font-size: 1.1rem;
    padding: 0.3rem 0.5rem;
    background-color: $color-belize;
    color: $color-white-100;
    border-radius: 0.4rem;
  }
  .app__user--account-sidebar-email {
    font-size: 1.2rem;
  }
  .app__user--account-sidebar-email-last-logged {
    color: $color-grey-500;
    font-size: 1.1rem;
  }
}

.app__user--account-sidebar-bottom {
  width: 100%;
  display: flex;
  justify-content: center;

  ul {
    width: 100%;

    li a {
      font-family: "Roboto-Thin";
      padding: 1.5rem 0.7rem;
      color: $color-grey-700;
      font-size: 1.4rem;
      display: flex;
      justify-content: flex-start;
      width: 100%;

      &:hover {
        background-color: $color-white-400;
      }

      &.app__user--account-sidebar-active {
        background-color: $color-white-400;
        color: $color-green-sea;
        border-right: 0.4rem solid $color-green-sea;
      }

      i {
        width: 2rem;
        margin-right: 2rem;
      }
    }
  }
}
