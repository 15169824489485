@import "abstract/variables";
@import "abstract/mixins";

.manage__tags-modal {
  width: 100%;
}
.manage__tags-input-container {
  border: 0.1rem solid $color-grey-400;
  border-radius: 0.4rem;

  .manage__tags-input-list-item-container {
    border: 0.1rem solid $color-brand-secondary;
    width: fit-content;
    border-radius: 0.3rem;
    margin: 0.5rem;
    padding: 0.4rem;
    font-size: 1.3rem;
    display: inline-block;

    .manage__tags-input-list-item {
      display: inline-block;
      width: fit-content;
      list-style: none;
      color: $color-brand-secondary;
    }

    .manage__tags-input-list-item-icon {
      margin-left: 0.2rem;
      color: $color-brand-secondary;
    }
  }
  .manage__tags-input-field {
    border: none;
  }
}

.manage__tags-dropdown-container {
  border: 0.1rem solid $color-grey-400;
  z-index: 10;
  position: relative;

  .manage__tags-dropdown-list-item {
    list-style: none;
    padding: 0.5rem;
    font-size: 1.2rem;

    &:hover {
      background-color: $color-info;
      color: $color-white-100;
    }
  }
}
