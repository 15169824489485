@import "../../abstract/variables";

.app__data--listing-header {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .app__data--listing-header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .app__data--listing-header-left-col {
      .app__data--listing-title {
        font-size: 2rem;
        font-family: $ff-heading-regular;
      }
    }

    .app__data--listing-header-right-col {
      display: flex;
      align-items: center;
    }
  }

  .app__data--listing-header-intro {
    font-size: 1.3rem;
    line-height: 2.2rem;
  }

  .app__data--listing--filter {
    padding-bottom: 1rem;

    .app__data--listing--filter-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.app__data--listing-body {
  background-color: $color-white-100;
  margin-top: 2rem;
  min-height: 40rem;
}
