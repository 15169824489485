@import "abstract/variables";

aside.app__navigation {
  grid-area: navigation;
  min-height: 100vh;
  background-color: $color-brand-dark-100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .app__navigation--icons-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }
}
