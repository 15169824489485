@import "abstract/variables";

.app__drawer-modal--header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(
    to right top,
    #17a2b8,
    #2badc2,
    #3ab7cc,
    #47c2d6,
    #54cde0
  );
  color: $color-white-100;
  padding: 1.5rem 1rem;

  i.close--icon {
    font-size: 2.1rem;
    line-height: 0;
    display: block;
  }
}
