@import "abstract/variables";
@import "abstract/mixins";

.Toastify__toast-body {
    font-size: 1.4rem;
  }
  
  .Toastify__toast--success,
  .Toastify__toast--info {
    background: $color-info;
  }
  
  .Toastify__toast--error {
    background: $color-danger;
  }
