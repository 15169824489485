@import "abstract/variables";
@import "abstract/mixins";
@import "global";
@import "layouts/listing/listing";

.app__data--listing--filter-menu-bar{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .app__data--listing--filter-menu-dropdown {
        margin-top: 1.1rem;
        margin-right: auto;
    }

    .app__user--account-usage-date-range-items {
        margin-right: 0.5rem;
    }
}

.app__user--account-usage-body {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-column-gap: 5rem;
    
        .app__user--account-usage-date-range {
            display: flex;
            flex-flow: row;
            margin: 1rem;
            margin-top: 5.5rem;
            row-gap: 5rem;
        }
        .app__user--account-usage-date-range-items {
            margin-right: 0.5rem;
            width: 100%;
        }
        .app__user--account-usage-table {
            margin-top : 2.5rem;
        }
       
}

.app__data--navigation-items{
    display: flex;
    font-size: 1.3rem;
    
    .app__data--navigation-item {
        margin: 0.3rem;

       
    }
}

.app__data--isactive{
  --border-left-size: 0.3rem;
  color: $color-white-100;
  border-bottom: var(--border-left-size) solid $color-brand-secondary;
  width: calc(var(--nav-item-width) - var(--border-left-size));
  color: $color-brand-secondary;
}

.app__user--account-usage-button{
    grid-area: "account table";
    text-align: right;
}
.app__user--export-button{
    background-color:#17a2b8;
    color: white;
    margin: 1rem;
    margin-top: 2rem;
    padding: 1rem;
    border: none;
    border-radius: 0.2rem;
    width: 10%;
}