@import "abstract/mixins";
@import "abstract/variables";

.app__dropdown {
  @include flex-center;
  background-color: $color-white-100;
  padding: 1rem;
  border: 1px solid $color-grey-200;
  border-radius: 0.4rem;
  position: relative;
  width: auto;

  .app__dropdown--icon-left {
    font-size: 1rem;
  }

  .app__dropdown--title {
    font-size: 1.3rem;
    color: $color-grey-800;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul.app__dropdown-menu-list {
    position: absolute;
    background-color: $color-white-100;
    white-space: nowrap;
    min-width: 100%;
    max-height: 50vh;
    z-index: 10;
    left: 0;
    top: 3.1rem;
    border: 1px solid $color-grey-200;
    opacity: 0;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    overflow-y: scroll;

    li {
      color: $color-grey-900;

      &.divider {
        border-top: 1px solid $color-grey-200;
      }

      div.app__dropdown--form {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;

        &:hover,
        :focus-within {
          background-color: $color-info;
          color: $color-white-100;
        }

        input[type="checkbox"] {
          width: 1.5rem;
          height: 1.5rem;
        }

        label.app__dropdown--form {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          font-size: 1.3rem;
          padding: 1rem;
        }
      }
    }
  }
}
