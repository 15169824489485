*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

html {
  font-size: 62.5%;
}

body {
  background-color: $background-primary;
  box-sizing: border-box;
  font-family: $ff-light;
}

html,
body {
  overflow: hidden;
}
