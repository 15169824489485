@import "abstract/variables";

.app__header {
  grid-area: head;
  height: var(--header-min-height);
  background-color: $color-white-100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 1px $color-grey-900;

  .app__header--left-col {
    position: relative;
    border-radius: 0.2rem;
    width: 30rem;
    padding: 1rem;
  }

  .app__header--right-col {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
}
