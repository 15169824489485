@import "abstract/mixins";
@import "abstract/variables";

.app__pagination {
  @include flex-center;
  font-size: 1.5rem;

  li.app__pagination--item,
  li.app__pagination--item > a {
    @include flex-center;
    width: 4rem;
    height: 3.5rem;
    color: $color-info;
    cursor: pointer;

    &:hover {
      background-color: $color-grey-100;
    }

    &.active,
    &.active a {
      background-color: $color-info;
      color: $color-white-100;
      font-weight: bold;
      cursor: default;
      border-radius: 0.4rem;

      &:hover {
        background-color: $color-info;
        color: $color-white-100;
      }
    }
  }

  li.disabled,
  li.disabled a,
  li.disabled a i {
    color: $color-grey-400;
    cursor: not-allowed;
    user-select: none;

    &:hover {
      background-color: transparent;
    }
  }
}
