@import "abstract/variables";
@import "abstract/mixins";

.app__user--password-wrapper {
  display: grid;
  place-items: center;
  height: 90%;

  .app__user--password-box {
    width: 50rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .app__user--password-left {
      width: 21rem;
      border-right: 0.1rem solid $color-white-700;

      .app__user--password-title {
        font-size: 1.4rem;
      }

      ul > li {
        font-size: 1.2rem;

        .app__user--password-check {
          animation: checkResize 0.5s ease-in-out;
        }

        @keyframes checkResize {
          from {
            transform: scale(2);
          }
          to {
            transform: scale(3);
          }
        }
      }
    }
  }
}