@import "abstract/variables";
@import "abstract/mixins";

.app__form-container {
  max-width: 300px;
  .text__input {
    position: relative;
    display: flex;
    align-items: center;

    &--icon {
      position: absolute;
      font-size: 1.2rem;
      color: $color-grey-700 &-left {
        left: 0.8rem;
      }
      &-right {
        right: 0.8rem;
      }
    }

    input {
      padding: 0.8rem 0.6rem;
    }
  }
}
