@import "abstract/mixins";
@import "abstract/variables";

.switch__wrapper {
  border: 0.1rem solid $color-grey-100;
  width: 6rem;
  border-radius: 0.4rem;

  .switch__container {
    @include flex-center;
    height: 100%;
    overflow: hidden;

    .switch__option {
      @include flex-center;
      padding: 0.8rem 1rem;
      font-size: 1.2rem;
      cursor: pointer;
      font-family: $ff-regular;
      width: 3rem;
      height: 100%;
      flex-grow: 2;
      transition: transform 0.3s linear;

      &.switch__option--yes {
        transform: translateX(3.1rem);
      }
      &.switch__option--no {
        transform: translateX(-3.1rem);
      }

      &.switch__option--bg-yes {
        background-color: $color-green-sea;
        color: $color-white-100;
      }

      &.switch__option--bg-no {
        background-color: $color-grey-100;
        color: $color-grey-500;
        font-size: 1.5rem;

        & i {
          visibility: hidden;
        }
      }
    }

    .switch__option--white {
      min-width: 6rem;
      height: 100%;
      background-color: $color-white-100;
    }
  }
}
