h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $ff-heading-light;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.75rem;
}

h5 {
  font-size: 1.5rem;
}

h6 {
  font-size: 1.25rem;
}

.lead {
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
}

del {
  text-decoration: line-through;
}

ins {
  text-decoration: underline;
}

a {
  color: $color-info;

  &:hover {
    color: $color-brand-primary;
  }
}

.help {
  font-family: $ff-light;
  font-size: 1.2rem;
}

strong {
  font-family: $ff-bold;
  font-weight: bold;
}

em {
  font-style: italic;
}

// Font Sizes
$fontSizes: (xs, "1"), (sm, "1.20"), (md, "1.4"), (lg, "3"), (xl, "4"),
  (xxl, "8");
@each $name, $size in $fontSizes {
  .text-#{$name} {
    font-size: #{$size}rem;
  }
}

// Font Sizes in Numbers
@for $size from 0 through 20 {
  .text-#{$size} {
    font-size: #{$size}px;
  }
}

// Text colors
$colors: (primary, $color-brand-primary), (success, $color-success),
  (muted, $color-muted), (info, $color-info), (warning, $color-warning),
  (danger, $color-danger), (dark, $color-dark), (white-100, $color-white-100),
  (white-200, $color-white-200), (white-300, $color-white-300),
  (white-400, $color-white-400), (white-500, $color-white-500),
  (white-600, $color-white-600), (white-700, $color-white-700),
  (white-800, $color-white-800), (white-900, $color-white-900),
  (grey-100, $color-grey-100), (grey-200, $color-grey-200),
  (grey-300, $color-grey-300), (grey-400, $color-grey-400),
  (grey-500, $color-grey-500), (grey-600, $color-grey-600),
  (grey-700, $color-grey-700), (grey-800, $color-grey-800),
  (grey-900, $color-grey-900), (grey-1000, $color-grey-1000),
  (green-sea, $color-green-sea), (orange, $color-orange),
  (carrot, $color-carrot), (pumpkin, $color-pumpkin), (indigo, $color-indigo),
  (river, $color-river), (belize, $color-belize), (wisteria, $color-wisteria),
  (mazrine, $color-mazrine), (berry, $color-berry), (taupe, $color-taupe);

@each $name, $hex in $colors {
  .text-#{$name} {
    color: #{$hex};
  }
  // Background colors
  .bg-#{$name} {
    background-color: #{$hex};
  }
  .bg-#{$name}-light {
    background-color: rgba($hex, 0.16);
  }
  .bdr-#{$name} {
    border: 0.1rem solid #{$hex};
  }
}

// Misc. Text Styles
code {
  background-color: $color-grey-100;
  color: $color-danger;
  padding: 0.9rem;
  font-size: 1rem;
  border-radius: 3px;
  width: fit-content;
}

pre {
  font-size: 1.2rem;
  font-family: $ff-thin;
}

.rotate-90 {
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
}

.text-upper {
  text-transform: uppercase;
}

// List
ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

// BLOCKQUOTES
blockquote {
  margin: 0 0 1rem;
  border-left: 0.25rem solid $color-grey-100;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;

  footer.blockquote-footer::before {
    content: "\2014\00A0";
  }

  footer.blockquote-footer {
    font-size: 1rem;
    color: $color-muted;
    margin-top: 1rem;
  }
}

blockquote.reverse {
  text-align: right;
  margin: 0 0 1rem;
  border: 0;
  border-right: 0.25rem solid $color-grey-100;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;

  footer.blockquote-footer::before {
    content: "\2014\00A0";
  }

  footer.blockquote-footer {
    font-size: 1rem;
    color: $color-muted;
    margin-top: 1rem;
  }
}
