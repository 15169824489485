@import "abstract/variables";

.app__2fa--tabs {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  font-size: 1.2rem;

  .app__2fa--tab {
    margin-right: 4rem;
    color: $color-grey-900;

    &-active {
      color: #000000;
      font-family: $ff-regular;
      font-weight: bold;
      &::after {
        content: "";
        width: 100%;
        display: block;
        margin-top: 0.5rem;
        border: 0.1rem solid $color-info;
      }
    }
  }
}
