@import "abstract/mixins";
@import "abstract/variables";

.add-customer__tabs {
  padding: 1.5rem;
  ul {
    display: flex;
    gap: 2rem;
    li {
      a {
        font-family: "Roboto-Regular";
        color: var(--color-grey-800);
        cursor: pointer;
        font-size: 1.3rem;
        text-transform: uppercase;
        transition: color 300ms linear;

        &:hover {
          color: $color-brand-primary;
        }
      }

      a.add-customer__active {
        color: $color-info;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1rem;
          border-bottom: 0.1rem solid $color-info;
        }
      }
    }
  }
}
