@import "abstract/variables";
@import "abstract/mixins";

.app__item--account--wrapper {
  @include flex-center;

  .app__item--account-card {
    width: 70%;
  }

  .app__item--title {
    font-family: $ff-heading-regular;
    font-size: 1.8rem;
    margin: 1rem 1rem 0 1rem;
  }

  .app__item--description {
    font-family: $ff-heading-regular;
    font-size: 1.4rem;
    margin: 1rem 1rem 1rem 1.2rem;
  }

  .app__item--navigation-items {
    display: flex;
    margin-bottom: 2rem;
  }
  .app__item--navigation-item {
    font-family: $ff-heading-regular;
    font-size: 1.2rem;
    color: $color-grey-900;
    margin: 1rem 1rem 0 1rem;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      color: darken($color-info, 25%);
    }
  }
}
.app__data--is-active {
  color: darken($color-info, 25%);
}
