@import "./abstract/mixins";
@import "./abstract/variables";
@import "global";

.app__wrapper {
  --header-min-height: 5rem;
  display: grid;
  grid-template-columns: 6rem auto;
  grid-template-rows: 6rem auto;
  grid-template-areas:
    "navigation head"
    "navigation content";

  .app__content {
    grid-area: content;
    max-height: calc(100vh - var(--header-min-height));
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 5rem;
  }
}
