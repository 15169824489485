.card {
  background-color: $color-white-100;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 0.5rem 0 rgba(43, 43, 43, 0.1),
    0 11px 6px -7px rgba(43, 43, 43, 0.1);
  border: none;
  margin-bottom: 3rem;
  transition: all 0.3s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
}

// Card Sizes
$cardSizes: (xs, "30"), (sm, "50"), (md, "60"), (lg, "70"), (xl, "80"),
  (xxl, "95");
@each $name, $size in $cardSizes {
  .card-#{$name} {
    width: #{$size}rem;
  }
}
