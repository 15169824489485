// Margins
$spacings: (
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50
);

@each $space in $spacings {
  // Line Heights
  .lh-#{$space} {
    line-height: #{$space * 3}px;
  }

  // Margins
  .m-#{$space} {
    margin: #{$space}px;
  }
  .my-#{$space} {
    margin: #{$space}px 0;
  }
  .mx-#{$space} {
    margin: 0 #{$space}px;
  }
  .mt-#{$space} {
    margin-top: #{$space}px;
  }
  .mb-#{$space} {
    margin-bottom: #{$space}px;
  }
  .ml-#{$space} {
    margin-left: #{$space}px;
  }
  .mr-#{$space} {
    margin-right: #{$space}px;
  }

  // Paddings
  .p-#{$space} {
    padding: #{$space}px;
  }
  .py-#{$space} {
    padding: #{$space}px 0;
  }
  .px-#{$space} {
    padding: 0 #{$space}px;
  }
  .pt-#{$space} {
    padding-top: #{$space}px;
  }
  .pb-#{$space} {
    padding-bottom: #{$space}px;
  }
  .pl-#{$space} {
    padding-left: #{$space}px !important;
  }
  .pr-#{$space} {
    padding-right: #{$space}px;
  }
}
