@import "abstract/mixins";
@import "abstract/variables";

.app__drawer-modal--wrapper {
  @include flex-center;

  .app__drawer-modal--menu {
    position: absolute;
    width: 45rem;
    top: 0;
    bottom: 0;
    background-color: $color-white-500;
    z-index: 1001;
    box-shadow: 0 0 3rem $color-grey-400;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    transition: right 0.5s ease-in-out;
    right: -1500px;

    &.app__drawer-modal--menu-show {
      right: 0;
    }
  }
}

.overlay {
  @include backdrop;
}
