@import "abstract/mixins";
@import "abstract/variables";

.login__wrapper {
  height: 100vh;
  overflow: hidden;

  .login__box--left {
    @include flex-center;
    background-color: $color-brand-dark-100;
    padding: 0 0.6rem;

    .login__news {
      width: 100%;

      .login__news--title {
        font-family: "Roboto-Bold";
        color: $color-brand-primary;
        font-size: 1.3rem;
        text-transform: uppercase;
        margin-bottom: 1rem;
      }

      .login__news--content a {
        display: block;
        color: $color-white-100;
        font-size: 1.3rem;
        margin-bottom: 1rem;

        &:hover {
          color: $color-white-800;
        }
      }

      .login__news--punchline {
        color: $color-grey-400;
        font-style: italic;
        line-height: 1.3rem;
        font-size: 1rem;
      }
    }
  }

  // Right Box
  .login__box--right {
    height: 100vh;
    align-self: center;

    .login__form {
      @include flex-center;
      @include flex-col;
      max-width: 30rem;
      height: 60rem;
      margin: auto;
      overflow: hidden;

      .login__text-welcome {
        justify-content: center;
        font-size: 2.1rem;
      }

      a.login__link {
        color: $color-info;
        transition: all 0.3s ease-out;

        &:hover {
          color: $color-brand-primary;
        }
      }

      .login__forms-container {
        width: 95%;
        position: relative;

        .login__form--signin {
          transition: transform 0.5s ease-out;
          transform: translateX(0);

          &.login__form--signin-hide {
            transform: translateX(-460%);
          }
        }

        .login__form--forgot-password {
          position: absolute;
          top: 0;
          transform: translateX(150%);
          transition: transform 0.5s ease-out;

          &.login__form--forgot-password-show {
            transform: translateX(0);
          }
        }
      }
    }
  }
}

// Remove third party elements (lastpass)
div[id^="__"] {
  display: none;
}